<template>
  <b-col
    lg="4"
    md="6"
    cols="12"
  >
    <b-card>
      <b-overlay
        :show="loadingEarnCashback"
        spinner-variant="primary"
        variant="light"
        blur="0"
        opacity="0.3"
      >
        <div class="d-flex justify-content-between h-[10rem] md:h-[13rem]">
          <div v-if="page === 1">
            <p class="text-2xl font-semibold">
              Penghematan Ongkir</p>
            <p class="text-4xl font-semibold mb-1">
              Rp {{ cashback.toLocaleString('id-ID') }}</p>
            <p class="mb-0">
              Total cashback yang kamu dapatkan semenjak menggunakan komship</p>
          </div>
          <div
            v-else
            class="w-100"
          >
            <div class="d-flex justify-content-between mb-1">
              <div>
                <p class="text-lg mb-0">
                  Hemat Ongkir</p>
                <p class="text-lg font-semibold mb-0">
                  Rp {{ earnState.total_earn_cashback_monthly.toLocaleString('id-ID') }}</p>
              </div>
              <div class="w-[100px] mb-auto mr-1">
                <flat-pickr
                  v-model="filterDateCashback"
                  :config="flatpickrMonthly"
                  class="form-control"
                  @input="updateEarnCashback()"
                />
              </div>
            </div>
            <p class="text-lg mb-0">
              Ongkir Retur (Berangkat)</p>
            <p class="text-lg font-semibold mb-0">
              Rp {{ earnState.total_benefit_retur_monthly.toLocaleString('id-ID') }}</p>
          </div>
          <div>
            <b-button
              variant="outline-secondary"
              class="btn-icon rounded-lg p-0"
              size="sm"
              :disabled="page === 1"
              @click="page = 1"
            >
              <feather-icon
                icon="ChevronUpIcon"
                size="25"
              />
            </b-button><br>
            <b-button
              variant="outline-secondary"
              class="btn-icon rounded-lg p-0 mt-[6px]"
              size="sm"
              :disabled="page === 2"
              @click="page = 2"
            >
              <feather-icon
                icon="ChevronDownIcon"
                size="25"
              />
            </b-button>
          </div>
        </div>
      </b-overlay>
    </b-card>
  </b-col>
</template>
<script>
import { mapState } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import moment from 'moment'
import flatPickr from 'vue-flatpickr-component'
import { Indonesian } from 'flatpickr/dist/l10n/id'
import MonthSelect from 'flatpickr/dist/plugins/monthSelect'
import '@/@core/scss/vue/libs/vue-flatpicker.scss'
import 'flatpickr/dist/plugins/monthSelect/style.css'

export default {
  components: { flatPickr },
  data() {
    return {
      page: 1,
      filterDateCashback: moment().format('YYYY-MM-DD'),
      flatpickrMonthly: {
        mode: 'single',
        locale: Indonesian,
        altInput: true,
        altFormat: 'j/n/Y',
        dateFormat: 'Y-m-d',
        maxDate: 'today',
        disableMobile: true,
        plugins: [
          new MonthSelect({
            shorthand: true,
            dateFormat: 'Y-m-d',
            altFormat: 'F Y',
          }),
        ],
      },
    }
  },
  computed: {
    ...mapState('dashboard', [
      'cashback',
      'earnState',
      'loadingEarnCashback',
    ]),
    ...mapFields('dashboard', [
      'earnStartDate',
      'earnEndDate',
    ]),
  },
  mounted() {
    this.updateEarnCashback()
  },
  methods: {
    async updateEarnCashback() {
      const startDate = moment(this.filterDateCashback).startOf('month').format('YYYY-MM-DD')
      const endDate = moment(this.filterDateCashback).endOf('month').format('YYYY-MM-DD')
      this.earnStartDate = startDate
      this.earnEndDate = endDate
      await this.$store.dispatch('dashboard/fetchEarnCashback')
    },
  },
}
</script>
<style>
.flatpickr-monthSelect-month.selected{
  background-color: #F95031 !important;
}
</style>
